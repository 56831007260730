<template>
  <b-card>
    <h3
      id="h.w6c5obdgs8y7"
      class="c1"
    >
      <span class="c8">Menambah kejadian penting</span>
    </h3>
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_4-0 start">
      <li class="c11 li-bullet-0">
        <span class="c5">Perhatikan menu sidebar klik &ldquo;Kejadian Penting&rdquo; untuk memunculkan list sub menu-nya, kemudian pilih dan klik pada &ldquo;Tambah Kejadian Penting&rdquo;, tunggu sebentar agar web mengalihkan ke page form kejadian penting</span>
      </li>
    </ul>
    <p class="c15">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image1.png')"
          style="width: 536.80px; height: 346.68px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c6 lst-kix_list_4-0">
      <li class="c3 li-bullet-1">
        <span class="c5">Anda akan dialihkan ke halaman form kejadian penting, silahkan isi sesuai dengan input yang disediakan, perlu diperhatikan beberapa input memiliki sifat mandatori (perihal, kategori, tanggal mulai, tanggal selesai, file) dan optional (tag, deskripsi, dan catatan)</span>
      </li>
    </ul>
    <p class="c4">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image3.png')"
          style="width: 535.49px; height: 268.03px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_4-0">
      <li class="c3 li-bullet-2">
        <span class="c5">Kemudian klik &ldquo;Simpan&rdquo; untuk menyimpan dokumen, akan muncul popup success apabila berhasil, kalau ada error maka ada beberapa input yang harus diperhatikan :</span>
      </li>
    </ul>
    <p class="c13">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image2.png')"
          style="width: 374.00px; height: 119.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c13 c10">
      <span class="c5" />
    </p>
    <h3
      id="h.95km9idc86ut"
      class="c1"
    >
      <span class="c8">Melihat daftar kejadian penting</span>
    </h3>
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_6-0 start">
      <li class="c9 li-bullet-1">
        <span class="c5">Perhatikan menu sidebar klik &ldquo;Kejadian Penting&rdquo; untuk memunculkan list sub menu-nya, kemudian pilih dan klik pada &ldquo;Daftar Kejadian Penting&rdquo;, tunggu sebentar agar web mengalihkan ke page daftar kejadian penting</span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image5.png')"
          style="width: 525.30px; height: 339.26px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <hr style="page-break-before:always;display:none;">
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_6-0">
      <li class="c2 li-bullet-1">
        <span class="c5">Anda akan dialihkan ke halaman daftar kejadian penting</span>
      </li>
    </ul>
    <p class="c7">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image4.png')"
          style="width: 530.21px; height: 265.39px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c6 lst-kix_list_6-0">
      <li class="c2 li-bullet-2">
        <span class="c5">Anda bisa mengunakan fitur pagination untuk melihat dokumen anda sebelumnya apabila dokumen anda sebelumnya tidak muncul pada daftar sekarang, dengan klik &ldquo;&gt;&rdquo; untuk selanjutnya dan klik &ldquo;&lt;&rdquo; untuk sebelumnya</span>
      </li>
    </ul>
    <p class="c7 c10">
      <span class="c5" />
    </p>
    <h3
      id="h.1u81yfbemnin"
      class="c1"
    >
      <span class="c8">Melihat detail kejadian penting</span>
    </h3>
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_7-0 start">
      <li class="c2 li-bullet-1">
        <span class="c5">Masuk ke halaman daftar kejadian penting, kemudain klik pada nomor unik di daftar</span>
      </li>
    </ul>
    <p class="c7">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image7.png')"
          style="width: 529.95px; height: 265.26px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c6 lst-kix_list_7-0">
      <li class="c2 li-bullet-1">
        <span class="c5">Anda akan dialihkan ke halaman detail kejadian penting, dan anda bisa melihat semua detail yang sudah tersedia</span>
      </li>
    </ul>
    <p class="c7">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image6.png')"
          style="width: 537.59px; height: 279.13px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <h3
      id="h.yj45959lweds"
      class="c1"
    >
      <span class="c8">Mengubah kejadian penting </span>
    </h3>
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_8-0 start">
      <li class="c2 li-bullet-1">
        <span class="c5">Masuk ke halaman daftar kejadian penting, kemudain klik pada nomor unik di daftar, untuk melanjutkan ke detail</span>
      </li>
    </ul>
    <p class="c14">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image7.png')"
          style="width: 529.95px; height: 265.26px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_8-0">
      <li class="c2 li-bullet-1">
        <span class="c5">Anda akan dialihkan ke halaman detail kejadian penting, klik tombol &ldquo;Edit Kejadian Penting&rdquo;, untuk melakukan perubahan dokumen</span>
      </li>
    </ul>
    <p class="c7">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image6.png')"
          style="width: 561.14px; height: 291.36px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7 c10">
      <span class="c5" />
    </p>
    <p class="c14">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image10.png')"
          style="width: 560.43px; height: 290.99px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c5" />
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c0">
      <span class="c5" />
    </p>
    <ul class="c6 lst-kix_list_8-0">
      <li class="c2 li-bullet-1">
        <span class="c5">Anda bisa menuju ke halaman &ldquo;Edit&rdquo; melalui daftar kejadian penting, dengan klik tombol action dan pilih serta klik &ldquo;Edit&rdquo;</span>
      </li>
    </ul>
    <p class="c7">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image8.png')"
          style="width: 560.87px; height: 280.73px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c6 lst-kix_list_8-0">
      <li class="c2 li-bullet-1">
        <span class="c5">Lakukan perubahaan dokumen sesuai dengan yang anda inginkan sesuai dengan aturan input yang tersedia :</span>
      </li>
    </ul>
    <p class="c7">
      <span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-4/image9.png')"
          style="width: 560.85px; height: 316.26px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c6 lst-kix_list_8-0">
      <li class="c2 li-bullet-2">
        <span class="c5">Klik tombol &ldquo;Simpan&rdquo; untuk menyimpan dokumen yang baru saja anda lakukan perubahan</span>
      </li>
      <li
        id="h.gjdgxs"
        class="c9 li-bullet-1"
      >
        <span class="c5">Masuk ke daftar / detail untuk melihat hasil perubahan</span>
      </li>
    </ul>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
.content-body img {
  display: block;
  max-width: 100%;
  padding-left: 35px;
  width: unset !important;
}
</style>
